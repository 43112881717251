
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AppInput from '@/components/atoms/AppInput.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import RadioButtonList from '@/components/molecules/RadioButtonList.vue'
import { ItemDownloadRequest, Trimming } from '@/data/@types/ItemDownloadRequest'
import { Content } from '@/data/@types/Content'
import { Index } from '@/data/@types/Index'
import { fileTypeList, imageSizeList } from '@/data/selector/downloadSelector'
import IconLoading from '@/components/atoms/IconLoading.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import { MyCollectionRecord } from '@/data/@types/MyCollectionRecord'
import { useRoute, useRouter } from 'vue-router'
import { downloadContents } from '@/domain/item/itemViewer/contentsDownload'
import { createItemTitleLabel } from '@/domain/item/itemViewer/itemTitleLabel'
import { getSelector } from '@/data/selector/getSelector'
import { getItemCopyright } from '@/domain/item/itemViewer/itemCopyright'
import { ViewMode } from '@/helpers/imageviewer/ImageViewer'
import { formatSpecificRange } from '@/helpers/util/formatSpecificRange'
import { DownloadType } from '@/data/@types/DownloadType'
import { sessionCheck } from '@/domain/session/sessionCheck'

export default defineComponent({
  components: {
    AppInput,
    AppButton,
    RadioButtonList,
    TextAlert,
    IconLoading,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const i18n = useI18n()
    const lang = i18n.locale
    const currentBundleNumber = computed(() => store.getters.bundleNumber)
    const currentContentNumber = computed(() => store.getters.contentNumber)
    const currentBundleContents = computed(() => store.getters.contents)
    const downloadingFlag = computed(() => store.getters.processingStatus === 'downloading')
    const mycollectionList = computed(() => store.getters.myCollectionAllItemList)
    const warningId = ref<string>('')
    const isLoggedIn = computed(() => store.getters.isLoggedIn)

    const fileTypes = computed(() => getSelector('fileTypeList', fileTypeList, i18n.t))
    const imageSize = computed(() => getSelector('imageSizeList', imageSizeList, i18n.t))

    const state = reactive({
      fileType: 'jpg',
      imageSize: 70,
      specificRange: String(currentContentNumber.value + 1),
    })

    // 特定のフィールドのみwatchで監視したいため、stateを分割
    const rangeState = reactive({
      val: 'specific',
    })

    const warning = ref<string>('')

    const checkInvalidRange = (size: number): boolean => {
      return formatSpecificRange(state.specificRange).split(',').some(panel => {
        // ハイフンを含まない場合（数字単体）
        if (!panel.includes('-')) {
          if (Number(panel) > size) return true
          return false
        }

        // ハイフンで2つの数字が連結されている場合
        const splitPanel = panel.split('-')
        if (Number(splitPanel[0]) > size) return true
        if (splitPanel[1] && Number(splitPanel[1]) > size) return true
        return false
      })
    }

    // ハイフンので連結された値が正しいかの確認 (ex.NG：5-3, OK: 3-5, 5-)
    const checkInvalidSerialNumber = () => {
      return formatSpecificRange(state.specificRange).split(',').some(panel => {
        if (panel.includes('-')) {
          // ハイフンで2つの数字が連結されている場合
          const splitPanel = panel.split('-')
          if (!splitPanel[1]) return false
          if (Number(splitPanel[0]) > Number(splitPanel[1])) return true
          return false
        }
        return false
      })
    }

    const getBid = (): string => {
      return props.item.contentsBundles ? props.item.contentsBundles[currentBundleNumber.value].id : ''
    }

    watch(currentContentNumber, () => {
      if (rangeState.val !== 'specific') return
      state.specificRange = String(currentContentNumber.value + 1)
    })

    // マイコレクションに追加したコンテンツ情報を取得
    watch(rangeState, () => {
      if (rangeState.val !== 'mycollection' || !mycollectionList.value.length) {
        state.specificRange = ''
        return
      }
      const currentItemData: MyCollectionRecord = mycollectionList.value
        .find((item: MyCollectionRecord) => item.pid === Number(route.params.pid))
      if (!currentItemData || !currentItemData.contents) return
      const contentsIndexList: Array<string> = []
      currentItemData.contents
        .forEach(content => {
          if (content.bid === getBid()) {
            const contentIndex = currentBundleContents.value.findIndex((c: Content) => c.id === content.cid) + 1
            // findIndexでhitしない場合はcontentIndex=0になる
            if (contentIndex) contentsIndexList.push(String(contentIndex))
          }
        })
      state.specificRange = contentsIndexList.join(',')
    })

    const getSpecificCids = (contents: Content[]): string[] => {
      const cids: string[] = []
      const contentLength = contents.length
      formatSpecificRange(state.specificRange).split(',').forEach(panel => {
        // ハイフンを含まない場合（数字単体）
        if (!panel.includes('-')) {
          cids.push(contents[Number(panel) - 1].id)
          return
        }

        // ハイフンで2つの数字が連結されている場合
        const splitPanel = panel.split('-')
        const init = Number(splitPanel[0]) - 1
        const last = splitPanel[1] ? Number(splitPanel[1]) - 1 : contentLength - 1
        contents.forEach((value, index) => {
          if (index >= init && index <= last) cids.push(value.id)
        })
      })
      return cids
    }

    const getCids = (contents: Content[]): string[] => {
      if (rangeState.val === 'all') {
        return contents.map(content => String(content.id))
      }

      if (rangeState.val === 'specific' || rangeState.val === 'mycollection') {
        return getSpecificCids(contents)
      }

      if (props.item.contentsBundles) {
        if ([ViewMode.TOW_IN_ONE].includes(store.getters.imageviewerViewMode)) {
          if (store.getters.processingData.addCoverPageFlag) {
            if (currentContentNumber.value === 0) {
              return [contents[currentContentNumber.value].id]
            }
            if (currentContentNumber.value % 2 === 0) {
              return [contents[currentContentNumber.value - 1].id, contents[currentContentNumber.value].id]
            }
            return contents[currentContentNumber.value + 1] ? [contents[currentContentNumber.value].id, contents[currentContentNumber.value + 1].id] : [contents[currentContentNumber.value].id]
          }
          if (currentContentNumber.value % 2 === 1) {
            return [contents[currentContentNumber.value - 1].id, contents[currentContentNumber.value].id]
          }
          return contents[currentContentNumber.value + 1] ? [contents[currentContentNumber.value].id, contents[currentContentNumber.value + 1].id] : [contents[currentContentNumber.value].id]
        }
        return [contents[currentContentNumber.value].id]
      }

      return []
    }

    const download = async () => {
      const cancelDownload = () => {
        // トーストに表示するアイテムのタイトル名を設定
        const itemTitle = createItemTitleLabel(props.item)
        store.commit('UPDATE_DOWNLOAD_STATUS', {
          status: 'session_check_download_error',
          fixedText: i18n.t('header.toaster.download_error_prefix') + itemTitle + i18n.t('header.toaster.download_error_suffix'),
        })
      }
      if (!await sessionCheck(route.fullPath, cancelDownload)) return

      const contents: Content[] = props.item.contentsBundles[currentBundleNumber.value]?.contents || []
      if (rangeState.val === 'specific' || rangeState.val === 'mycollection') {
        // 半角数字とカンマ、ハイフン以外の文字が入力されている場合
        const rangeCaractor = new RegExp('^([-,]|\\d)+$', 'g')
        if (!formatSpecificRange(state.specificRange).match(rangeCaractor)) {
          warning.value = i18n.t('downloadPanel.warningMessageA')
          warningId.value = 'unexpected-charactor'
          return
        }
        // カンマ、ハイフンがそれぞれ連続する場合、ハイフンの前が入力されていない場合
        const baseRange = '((\\d+-\\d*)|\\d+)'
        const rangeFormat = new RegExp(`^${baseRange}(,${baseRange})*$`, 'g')
        if (!formatSpecificRange(state.specificRange).match(rangeFormat)) {
          warning.value = i18n.t('downloadPanel.warningMessageB')
          warningId.value = 'lack-of-number'
          return
        }
        // ハイフンの前の値が後ろの値より大きい場合 (ex. 5-3)
        if (checkInvalidSerialNumber()) {
          warning.value = i18n.t('downloadPanel.warningMessageB')
          warningId.value = 'small-number-after-large-number'
          return
        }
        // 入力値が元のバンドルサイズを超えている場合
        if (checkInvalidRange(contents.length)) {
          warning.value = i18n.t('downloadPanel.warningMessageC')
          warningId.value = 'out-of-range'
          return
        }
      }
      warning.value = ''

      const itemName = store.getters.item?.meta['0001Dtct'][0] || '0001'
      const bundleName = store.getters.currentContentsBundle?.name?.ja || '0001'

      const pid = props.item.pid.split('/').slice(-1).toString()
      const bid = getBid()
      const cid = getCids(contents)

      let fileName = ''
      let contentsToc: string[][] = []
      const indices: Index[] = props.item.contentsBundles[currentBundleNumber.value]?.indices
      if (cid.length === 1) {
        // ダウンロード時のファイル名 = digidepo_{PIDの数字部分}_{オリジナルファイル名(拡張子なし)}
        const currentContent = contents.filter(c => c.id === cid[0])[0]
        fileName = `digidepo_${pid}_${currentContent.originalFileName?.split('.').slice(0, -1).join('.') || '0001'}`
      } else {
        fileName = `${itemName}_${pid}_${bundleName}`
      }
      // 単ページ表示、2in1表示 のときは目次を出さない
      if (state.fileType === 'pdf' && rangeState.val === 'all' && ![ViewMode.SINGLE, ViewMode.TOW_IN_ONE].includes(store.getters.imageviewerViewMode)) {
        contentsToc = contents.map(content => {
          if (indices) {
            const mappedIndices = indices.filter(index => {
              return index.contentId === content.id
            })
            return mappedIndices.map(mappedIndex => {
              return mappedIndex.index
            })
          } else {
            return []
          }
        })
      }
      const processingData = store.getters.processingData

      const margin: any = {}
      processingData.autoClipFlag && cid.forEach(id => {
        const content = contents.find(c => c.id === id)
        const layout = content?.extra?.layout?.main
        margin[id] = layout
      })
      const autoRotation: any = {}
      if (processingData.autoClipFlag && [ViewMode.SINGLE].includes(store.getters.imageviewerViewMode)) {
        cid.forEach(id => {
          const content = contents.find(c => c.id === id)
          const leftRotation = content?.extra?.layout?.leftRotation?.degree
          const rightRotation = content?.extra?.layout?.rightRotation?.degree
          autoRotation[id] = [leftRotation, rightRotation]
        })
      }

      const item = {
        pid,
        bid,
        cid,
        all: rangeState.val === 'all',
        toc: contentsToc,
        fileName,
        title: itemName,
        author: store.getters.item?.meta['0010Dtct']?.[0] || '',
        keyword: getItemCopyright(props.item),
        margin: Object.keys(margin).length ? margin : null,
        autoRotation: Object.keys(autoRotation).length ? autoRotation : null,
      }

      const option = {
        type: state.fileType,
        size: state.imageSize,
        function: 'download',
        filter: processingData,
        pattern: [Number(processingData.divisionHorizontal), Number(processingData.divisionVertical)],
        frame: processingData.croppedRange && !([ViewMode.SINGLE].includes(store.getters.imageviewerViewMode)) ? {
          x: processingData.croppedRange.left || 0,
          y: processingData.croppedRange.top || 0,
          w: processingData.croppedRange.width || 100,
          h: processingData.croppedRange.height || 100,
        } : null,
        mode: rangeState.val === 'all' && [ViewMode.TOW_IN_ONE].includes(store.getters.imageviewerViewMode) ? '2in1' : 'koma',
        cover2in1: store.getters.processingData.addCoverPageFlag,
        includeCover: currentContentNumber.value === 0 || (currentContentNumber.value === 1 && !store.getters.processingData.addCoverPageFlag) || rangeState.val === 'all',
      }

      const cidList: string[] = []
      const divideList: number[][] = []
      const trimmingList: Trimming[] = []
      if ([ViewMode.SINGLE].includes(store.getters.imageviewerViewMode)) { // 片ページ表示のとき
        const contents = store.getters.currentContentsBundle.contents
        item.cid.forEach(id => {
          const content = contents.find((c: Content) => c.id === id)
          const divide = content?.extra?.layout?.divide
          if (divide && divide > 0 && divide < 1) {
            cidList.push(id)
            cidList.push(id)
            divideList.push([0, divide * 100])
            divideList.push([divide * 100, 100])
          } else {
            cidList.push(id)
            divideList.push([0, 100])
          }
        })
      } else {
        item.cid.forEach(cid => {
          cidList.push(cid)
          divideList.push([0, 100])
        })
      }

      if (processingData.autoClipFlag) {
        cidList.forEach(cid => {
          const content: Content = store.getters.currentContentsBundle.contents.find((c: Content) => c.id === cid)
          const main = content.extra?.layout?.main
          const trimming: Trimming = main ? {
            Left: main.x,
            Right: main.x + main.w,
            Top: main.y,
            Bottom: main.y + main.h,
          } : {
            Left: 0,
            Right: 100,
            Top: 0,
            Bottom: 100,
          }
          trimmingList.push(trimming)
        })
      } else {
        cidList.forEach(() => {
          trimmingList.push(processingData.croppedRange ? {
            Left: processingData.croppedRange.left,
            Top: processingData.croppedRange.top,
            Right: processingData.croppedRange.width + processingData.croppedRange.left,
            Bottom: processingData.croppedRange.height + processingData.croppedRange.top,
          } : {
            Left: 0,
            Top: 0,
            Right: 100,
            Bottom: 100,
          })
        })
      }

      // 最大1000コマまでダウンロード可能
      if (cidList.length > 500) {
        warning.value = i18n.t('downloadPanel.warningMessageD')
        warningId.value = 'out-of-range'
        return
      }

      const downloadParam: ItemDownloadRequest = {
        item: { ...item, cid: cidList },
        option: { ...option, divides: divideList, trimming: trimmingList },
      }
      const itemTitle = createItemTitleLabel(props.item)

      downloadContents(store, downloadParam, itemTitle, i18n, DownloadType.IMAGE)
    }

    // 非活性のフィールドを追加する必要があるためコンポーネント内で定義
    const ranges = computed(() => [
      { value: 'all' },
      { value: 'specific' },
      { value: 'mycollection', disabled: !isLoggedIn.value },
    ])
    const rangesLocal = computed(() => getSelector('downloadRanges', ranges.value, i18n.t))

    const canDownloadWithRandomLimit = ref(true)
    // onMounted(async () => {
    //   canDownloadWithRandomLimit.value = await randomLimit()
    // })

    return {
      state,
      rangeState,
      warning,
      warningId,
      download,
      fileTypes: fileTypes,
      imageSizes: imageSize,
      rangesLocal,
      downloadingFlag,
      canDownloadWithRandomLimit,
    }
  },
})
