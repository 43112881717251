import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64acebad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-download has-background-contents" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "panel-download-file-type" }
const _hoisted_4 = { class: "panel-download-image-size" }
const _hoisted_5 = { class: "panel-download-range" }
const _hoisted_6 = {
  key: 0,
  class: "panel-download-specific-range"
}
const _hoisted_7 = { class: "panel-download-specific-input" }
const _hoisted_8 = { class: "panel-download-specific-doc" }
const _hoisted_9 = { class: "level-right is-mobile" }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButtonList = _resolveComponent("RadioButtonList")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_TextAlert = _resolveComponent("TextAlert")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_IconLoading = _resolveComponent("IconLoading")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('downloadPanel.title')), 1),
    (_ctx.canDownloadWithRandomLimit)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("dl", null, [
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('downloadPanel.fileType')), 1),
            _createElementVNode("dd", _hoisted_3, [
              _createVNode(_component_RadioButtonList, {
                modelValue: _ctx.state.fileType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.fileType) = $event)),
                items: _ctx.fileTypes,
                id: "file-type",
                name: "file-type"
              }, null, 8, ["modelValue", "items"])
            ]),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('downloadPanel.size')), 1),
            _createElementVNode("dd", _hoisted_4, [
              _createVNode(_component_RadioButtonList, {
                modelValue: _ctx.state.imageSize,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.imageSize) = $event)),
                items: _ctx.imageSizes,
                id: "image-size",
                name: "image-size",
                styled: "line-up"
              }, null, 8, ["modelValue", "items"])
            ]),
            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('downloadPanel.range')), 1),
            _createElementVNode("dd", _hoisted_5, [
              _createVNode(_component_RadioButtonList, {
                modelValue: _ctx.rangeState.val,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rangeState.val) = $event)),
                items: _ctx.rangesLocal,
                id: "range",
                name: "range"
              }, null, 8, ["modelValue", "items"]),
              (_ctx.rangeState.val === 'specific' || _ctx.rangeState.val === 'mycollection')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_AppInput, {
                        modelValue: _ctx.state.specificRange,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.specificRange) = $event)),
                        type: "string",
                        placeholder: "1,3,6",
                        id: "range-specific-input",
                        "aria-label": _ctx.$t('printModal.inputRangeAriaLabel')
                      }, null, 8, ["modelValue", "aria-label"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('printModal.inputRangeGuideMessage')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.warning)
                ? (_openBlock(), _createBlock(_component_TextAlert, {
                    key: 1,
                    class: "printing-modal-range-caution",
                    "data-cy": _ctx.warningId
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.warning), 1)
                    ]),
                    _: 1
                  }, 8, ["data-cy"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_AppButton, {
              id: "panel-download-download-button",
              onClick: _ctx.download,
              onKeydown: _withKeys(_withModifiers(_ctx.download, ["prevent"]), ["space","enter"]),
              styled: "is-accent",
              disabled: _ctx.downloadingFlag
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('label.download')), 1)
              ]),
              _: 1
            }, 8, ["onClick", "onKeydown", "disabled"]),
            (_ctx.downloadingFlag)
              ? (_openBlock(), _createBlock(_component_IconLoading, {
                  key: 0,
                  class: "download-panel-icon-loading"
                }))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('label.downloadRandomLimit')), 1))
  ]))
}